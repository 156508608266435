import { CommunityDashboardUser, DashboardExtended, SummaryStatRecord } from "@/includes/types/DashboardExtended/types";
import DashboardErrors from "@/components/CommunityDashboard/DashboardErrors.vue";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

export default class DashboardApi {

  static async getDashBoardStatus(chat_id: number) {
    return PostApi.sendRequest<{ errors: Array<DashboardErrors> }>('tg', 'getdashboardstatus', { chat_id })
  }

  static async getDashBoardSummaryStatistics(chat_id: number) {
    return PostApi.sendRequest<{ summary_statistics: Array<SummaryStatRecord>}>('tg', 'getdashboardsummarystatistics', { chat_id })
  }

  static async getDashBoardCommunityStatistics(chat_id: number) {
    return PostApi.sendRequest<DashboardExtended>('tg', 'getdashboardcommunitystatistics', { chat_id })
  }

  static async getUsersCommunityStat(chat_id: number, user_ids: Array<number>) {
    return PostApi.sendRequest<{ users: Array<CommunityDashboardUser> }>('tg', 'getuserscommunitystat', { chat_id, user_ids })
  }
}
